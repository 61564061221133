<template>
    <div>
        <VueDatePicker v-model="tempChartDate" color="#285891" fullscreen-mobile :format="formattedDate"
            @onChange="applyFinalDate" :allow-overflow="false" :locale="{ lang: lang }"
            :type="timeSelected === 'week' ? 'date' : timeSelected === 'Month' ? 'date' : timeSelected === 'year' ? 'year' : timeSelected ==='total' ? 'total' : timeSelected"
            :max-date="today" />
    </div>
</template>

<script>
export default {
    props: {
        initialDate: {
            type: String,
            required: true,
        },
        timeSelected: {
            type: String,
            required: true,
        },
        lang: {
            type: String,
            default: 'en'
        },
    },
    data() {
        return {
            today: new Date(),
            tempChartDate: this.initialDate || this.$dayjs(this.today).format('DD.MM.YYYY'),
        };
    },
    computed: {
        formattedDate() {
            let tem = '';
            let dateRegex_week = /^\d{2}\.\d{2}\.\d{4}$/;
            let date_week = /^\d{4}\-\d{2}\-\d{2}$/;
            let dateRegex_month = /^\d{2}\.\d{4}$/;
            let date_month = /^\d{4}\-\d{2}$/;
            let dateRegex_year = /^\d{4}$/;
            if (this.timeSelected === 'week') {
                if (dateRegex_week.test(this.tempChartDate)) {
                    tem = this.tempChartDate;
                } else if (dateRegex_month.test(this.tempChartDate) || date_month.test(this.tempChartDate) || date_week.test(this.tempChartDate)) {
                    tem = this.$dayjs(this.tempChartDate).format('DD.MM.YYYY');
                    // return tem;
                }
                else {
                    tem = this.$dayjs(this.tempChartDate).format('DD.MM.YYYY');
                    // return tem;
                }
            } else if (this.timeSelected === 'month') {
                if (this.tempChartDate.includes('.')) {
                    const [day, month, year] = this.tempChartDate.split('.');
                    // return `${month}.${year}`
                    tem = `${month}.${year}`;
                } else if (this.tempChartDate.includes('-')) {
                    const [year, month, day] = this.tempChartDate.split('-');
                    // return `${month}.${year}`
                    tem = `${month}.${year}`;
                }
                else {
                    tem = this.$dayjs(this.tempChartDate).format('MM.YYYY');
                }
            } else if (this.timeSelected === 'year') {
                tem = this.$dayjs(this.tempChartDate).format('YYYY');
            }
            
            this.applyFinalDate();
            return tem;
        },
    },
    methods: {
        applyFinalDate() {
            this.$emit('date-selected', this.tempChartDate);
        },
    },
    watch: {
        tempChartDate(newValue) {
            console.log('WATCH tempChartDate updated:', newValue);
        }
    }
};
</script>
<style scoped>
.vd-wrapper {
    max-width: 7rem !important;
    right: 0 !important;
    top: .1rem !important;
}
</style>
<style>
.vd-picker__input-icon {
    margin-top: 2.8px !important;
}
</style>