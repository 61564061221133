<template>
  <div>
    <!-- <h5 class="info-text">{{$t('update.step3header')}}</h5> -->
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        {{ $t('update.step3description') }}
      </div>
      <div>
        <img class="update_finish" :src="Updatefertig" alt="Update_Fertig">
      </div>
    </div>
  </div>
</template>
<script>
import UpdateFertig from '@/assets/images/Update_Fertig.jpg';
export default {
  components: {
   
  },
  data() {
    return {
      Updatefertig: UpdateFertig
    };
  },
  methods: {

    validate() {
      return this.$refs.form.validate().then(res => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        return res;
      });
    }
  }
};
</script>
<style>
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .update_finish {
    height: 10rem;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .update_finish {
    height: 20rem;
    margin-top: 3rem;
  }
}
</style>
