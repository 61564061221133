<template>
    <div>

        <div class=" container-fluid ">

            <div class="row mt-5">
                <div class="col col-sm-12 d-flex justify-content-center ">

                    <div class="card-style card">

                        <div>
                            <h1 class="text-center">{{ $t('settings.header') }}</h1>

                            <div class="col-md-8 center" style="margin-bottom: 20px;">
                                <el-select style="width: 100%;"
                                    :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }" size="midium"
                                    @change="getSelectSettings" :placeholder="$t('update.selectormessage')"
                                    v-model="selectedSn">
                                    <el-option v-for="sn in getAllSn" :key="sn" :value="sn">{{ sn }}</el-option>
                                    <el-option :value="null" disabled selected hidden>{{ getAllSn.length === 1 ?
                                selectedSn = getAllSn[0] : $t('update.selectormessage') }}</el-option>
                                </el-select>
                            </div>
                            <transition name="fade">
                                <div v-if="selectedSn">
                                    <!-- <div class="row" style="margin-bottom: 20px;">
                                        <div class="col-md-6">
                                            <p>{{ $t('settings.EmergencyPower') }}</p>
                                        </div>
                                        <div class="col-md-6 setting-switch">
                                            <base-switch type="primary" v-model="EmergencyPower"
                                                :on-text="$t('settings.on')" :off-text="$t('settings.off')"
                                                :on-text-color="darkMode ? 'white' : 'black'"
                                                :off-text-color="darkMode ? 'white' : 'black'"></base-switch>
                                        </div>
                                    </div> -->
                                    <!-- <transition name="fade">
                                        <div v-if="EmergencyPower" class="settings">
                                            <h3>{{ $t('settings.additional') }}</h3>
                                            <h4 class="text-left">{{ $t('settings.ChargingPower') }}</h4>
                                            <div class="col" :class="{ 'dark-tooltips': darkMode }">
                                                <slider v-model="sliders.ChargingPower" type="primary"
                                                    :range="{ min: 100, max: 2500 }" :options="{
                                                        step: 50, tooltips: true, format: {
                                                            to: (value) => {
                                                                return `${Math.round(value)} W`;
                                                            },
                                                            from: (value) => {
                                                                return Number(value.replace(' W', ''));
                                                            }
                                                        },
                                                }" style="margin-top: 3rem;"> </slider> <br />
                                            </div>
                                            <div class="row" style="margin-bottom: 20px;">
                                                <div class="col">
                                                    <el-select style="width: 100%;"
                                                        :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }"
                                                        size="small" :placeholder="$t('settings.reserve')"
                                                        v-model="reservesoc">
                                                        <el-option v-for="option in selects.reservesoc"
                                                            :value="option.value" :label="option.label"
                                                            :key="option.label">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                        </div>
                                    </transition> -->

                                    <!-- <div class="row" style="margin-bottom: 20px;">
                                        <div class="col-md-6">
                                            <p>{{ $t('settings.dynamicElectricityTariff') }}</p>
                                        </div>
                                        <div class="col-md-6 setting-switch">
                                            <base-switch type="primary" v-model="dynamicElectricityTariff"
                                                :on-text="$t('settings.on')" :off-text="$t('settings.off')"
                                                :on-text-color="darkMode ? 'white' : 'black'"
                                                :off-text-color="darkMode ? 'white' : 'black'"></base-switch>
                                        </div>
                                    </div> -->
                                    <!-- <transition name="fade">
                                        <div v-if="dynamicElectricityTariff" class="settings">
                                            <h3>{{ $t('settings.additional') }}</h3>

                                            <div class="row">
                                                <div class="col-md-6 setting-item">
                                                    <h4 slot="header" class="card-title">{{ $t('settings.startTimeCha')
                                                        }}
                                                    </h4>
                                                    <base-input>
                                                        <el-time-select :placeholder="$t('settings.timePicker')"
                                                            v-model="timePicker_startCha"
                                                            :picker-options="pickerOptions_start">
                                                        </el-time-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <h4 slot="header" class="card-title">{{ $t('settings.endTimeCha') }}
                                                    </h4>
                                                    <base-input>
                                                        <el-time-select class="custom-time-select"
                                                            :placeholder="$t('settings.timePicker')"
                                                            v-model="timePicker_endCha"
                                                            :picker-options="pickerOptions_endCha"
                                                            :disabled="!timePicker_startCha">
                                                        </el-time-select>
                                                    </base-input>
                                                </div>
                                            </div>



                                            <div class="row">
                                                <div class="col-md-6 setting-item">
                                                    <h4 slot="header" class="card-title">{{ $t('settings.startTimeDis')
                                                        }}
                                                    </h4>
                                                    <base-input>
                                                        <el-time-select :placeholder="$t('settings.timePicker')"
                                                            v-model="timePicker_startDis"
                                                            :picker-options="pickerOptions_start">
                                                        </el-time-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <h4 slot="header" class="card-title">{{ $t('settings.endTimeDis') }}
                                                    </h4>
                                                    <base-input>
                                                        <el-time-select class="custom-time-select"
                                                            :placeholder="$t('settings.timePicker')"
                                                            v-model="timePicker_endDis"
                                                            :picker-options="pickerOptions_endDis"
                                                            :disabled="!timePicker_startDis">
                                                        </el-time-select>
                                                    </base-input>
                                                </div>
                                            </div>
                                            <h4 class="text-left">{{ $t('settings.ChargingPrice') }}</h4>
                                            <div class="col" :class="{ 'dark-tooltips': darkMode }">
                                                <slider v-model="sliders.ChargingPrice" type="primary"
                                                    :range="{ min: 20, max: 50 }" :options="{
                                step: .5, tooltips: true, format: {
                                    to: (value) => {
                                        return `${value} ct/kWh`;
                                    },
                                    from: (value) => {
                                        return Number(value.replace(' ct/kWh', ''));
                                    }
                                },
                            }" style="margin-top: 3rem;"> </slider> <br />
                                            </div>
                                            <div class="row" style="margin-bottom: 20px;">
                                                <div class="col-md-6">
                                                    <el-select
                                                        :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }"
                                                        size="small" :placeholder="$t('settings.ElectricityProvider')"
                                                        v-model="selectedProvider">
                                                        <el-option v-for="option in selects.ElectricityProvider"
                                                            :value="option.value" :label="option.label"
                                                            :key="option.label">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div v-if="isProviderSelected">
                                                <div class="settings setting-item" v-if="isTokenReq">
                                                    <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }"
                                                        type="text" :placeholder="$t('settings.token')"
                                                        v-model="TheToken">
                                                </div>
                                                 <div class="col-md-6" v-else>
                                            <el-input placeholder="Enter first detail"
                                                v-model="firstDetail"></el-input>
                                            <el-input placeholder="Enter second detail"
                                                v-model="secondDetail"></el-input>
                                        </div> 
                                            </div>
                                        </div>
                                    </transition> -->


                                    <div class="row" style="margin-bottom: 20px;">
                                        <div class="col-md-6">
                                            <p>{{ $t('settings.Parameters') }}</p>
                                        </div>
                                        <div class="col-md-6 setting-switch">
                                            <base-switch type="primary" v-model="Parameters"
                                                :on-text="$t('settings.on')" :off-text="$t('settings.off')"
                                                :on-text-color="darkMode ? 'white' : 'black'"
                                                :off-text-color="darkMode ? 'white' : 'black'"></base-switch>
                                        </div>
                                    </div>
                                    <transition name="fade">
                                        <div v-if="Parameters" class="settings">
                                            <h3>{{ $t('settings.additional') }}</h3>
                                            <h4 class="text-left">{{ $t('settings.chargelimit') }}</h4>
                                            <div class="col" :class="{ 'dark-tooltips': darkMode }">
                                                <slider v-model="sliders.chargelimit" type="primary"
                                                    :range="{ min: 0, max: 30 }" :options="{
                                step: 1, tooltips:
                                    true, format: {
                                        to: (value) => {
                                            return `${Math.round(value)} %`;
                                        },
                                        from: (value) => {
                                            return Number(value.replace(' %', ''));
                                        }
                                    },
                            }" style="margin-top: 3rem;"> </slider> <br />
                                            </div>
                                            <h4 class="text-left">{{ $t('settings.dischargelimit') }}</h4>
                                            <div class="col" :class="{ 'dark-tooltips': darkMode }">
                                                <slider v-model="sliders.dischargelimit" type="primary"
                                                    :range="{ min: 0, max: 100 }" :options="{
                                step: 1, tooltips:
                                    true, format: {
                                        to: (value) => {
                                            return `${Math.round(value)} %`;
                                        },
                                        from: (value) => {
                                            return Number(value.replace(' %', ''));
                                        }
                                    },
                            }" style="margin-top: 3rem;"> </slider> <br />
                                            </div>

                                            <div class="row" style="margin-bottom: 20px; margin-top: 20px;">
                                                <div class="col">
                                                    <el-select style="width: 100%;"
                                                        :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }"
                                                        size="small" :placeholder="$t('settings.SmartMeterType')"
                                                        v-model="SmartMeterType">
                                                        <el-option v-for="option in selects.SmartMeterType"
                                                            :value="option.value" :label="option.label"
                                                            :key="option.label">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>

                                            <div class="settings setting-item row">
                                                <div class="col">
                                                    <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }"
                                                        type="text" :placeholder="$t('settings.SmartMeterAddress')"
                                                        v-model="SmartMeterAddress" @input="validateNumber">
                                                </div>
                                            </div>

                                            <div class="row" style="margin-bottom: 20px; margin-top: 20px;">
                                                <div class="col">
                                                    <el-select style="width: 100%;"
                                                        :class="{ 'select-primary': !darkMode, 'select-secondary': darkMode }"
                                                        size="small" :placeholder="$t('settings.P_Control')"
                                                        v-model="P_Control">
                                                        <el-option v-for="option in selects.P_Control"
                                                            :value="option.value" :label="option.label"
                                                            :key="option.label">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-5 checkbox-radios">
                                                    <div @click.prevent="uncheckPI" class="uncheck"
                                                        style="z-index: -10;">
                                                        <base-radio v-model="checkboxes.PlugIn_UPS" name="PlugIn">
                                                            {{ $t('settings.PlugIn') }}</base-radio>
                                                    </div>
                                                    <div @click.prevent="uncheckUPS" class="uncheck">
                                                        <base-radio v-model="checkboxes.PlugIn_UPS" name="UPS">
                                                            {{ $t('settings.UPS') }}</base-radio>
                                                    </div>
                                                </div>
                                                <div class="col-sm-10 checkbox">
                                                    <base-checkbox v-model="checkboxes.SmartMeterTest">
                                                        {{ $t('settings.SmartMeterTest') }}</base-checkbox>
                                                    <base-checkbox v-model="checkboxes.P_limitTCP">
                                                        {{ $t('settings.P_limitTCP') }}</base-checkbox>
                                                </div>
                                            </div>

                                        </div>
                                    </transition>

                                    <div class="d-flex justify-content-center  align-items-center">
                                        <button class="btnn btn-info" @click="submit_settings">{{ $t('settings.save')
                                            }}</button>
                                    </div>
                                    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row mt-5">
                <div class="col col-sm-12 d-flex justify-content-center ">

                    <div class="card-style card">
                        <h1 class="text-center">{{ $t('settings.addSN') }}</h1>
                        <div class="card-container">
                            <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }" type="text"
                                :placeholder="$t('settings.serialnumber1')" v-model="values.serialNumber1">

                            <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }" type="text"
                                :placeholder="$t('settings.serialnumber2')" v-model="values.serialNumber2">
                            <div v-if="count >= 1">
                                <div v-for="key in count" :key="key">
                                    <input :class="{ 'text-white': !darkMode, 'text-dark': darkMode }" type="text"
                                        :placeholder="$t('settings.another')" v-model="values['dynamic-field-' + key]"
                                        :id="key">
                                </div>
                            </div>

                        </div>
                        <div :class="{ 'controler': true, 'text-white': !darkMode, 'text-dark': darkMode }"
                            style="cursor: pointer;">
                            <a id="add_more_fields" @click="add"><i class="fa fa-plus"></i>{{
                                $t('settings.addmore') }}</a>
                            <a id="remove_fields" @click="remove"><i class="fa fa-minus"></i>{{
                                $t('settings.removefields') }}</a>

                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <button class="btnn btn-info" @click="submit">{{ $t('settings.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>





</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useApi } from "@/modules/api";
import axios from 'axios';
import { mapState } from 'vuex';
import { TimeSelect, Select, Option } from 'element-ui'
import { BaseSwitch, Slider, BaseCheckbox, BaseRadio } from 'src/components/index';

export default {
    name: 'Settings',
    data() {
        return {
            count: 1,
            // dynamicElectricityTariff: false,
            //EmergencyPower: false,
            Parameters: false,
            timePicker_startCha: '',
            timePicker_endCha: '',
            timePicker_startDis: '',
            timePicker_endDis: '',
            reservesoc: null,
            SmartMeterType: '',
            P_Control: '',
            selectedProvider: null,
            selectedSn: null,
            TheToken: '',
            firstDetail: '',
            secondDetail: '',
            // EPerrorMessage: '',
            errorMessage: '',
            SmartMeterAddress: '',
            pickerOptions_start: {
                start: '00:00',
                end: '23:59',
                step: '00:30',
                maxTime: '23:29'
            },
            pickerOptions_endCha: {
                start: '00:00',
                end: '23:59',
                step: '00:30',
                minTime: '00:00'
            },
            pickerOptions_endDis: {
                start: '00:00',
                end: '23:59',
                step: '00:30',
                minTime: '00:00'
            },
            values: {
                serialNumber1: '',
                serialNumber2: '',
            },
            selects: {
                reservesoc: [
                    { value: 10, label: '10%' },
                    { value: 20, label: '20%' },
                    { value: 30, label: '30%' },
                    { value: 40, label: '40%' },
                    { value: 50, label: '50%' },
                    { value: 60, label: '60%' },
                    { value: 70, label: '70%' },
                    { value: 80, label: '80%' },
                    { value: 90, label: '90%' },
                    { value: 100, label: '100%' }
                ],
                ElectricityProvider: [
                    { value: 'Tibber', label: 'Tibber' },
                    { value: 'aWATTar', label: 'aWATTar' },
                    { value: 'Stromdao', label: 'Stromdao' },
                    { value: 'Stadtwerk Hassfurt', label: 'Stadtwerk Hassfurt' },
                    { value: 'rabot.charge', label: 'rabot.charge' }
                ],
                SmartMeterType: [
                    { value: '1', label: 'ADL400' },
                    { value: '2', label: 'AEW100' },
                    { value: '3', label: 'ADW400' },
                    { value: '4', label: 'ADW220' },
                    { value: '5', label: 'SQM630' }
                ],
                P_Control: [
                    { value: 'fromSM_RS485', label: `${this.$t('settings.fromSM_RS485')}` },
                    { value: 'fromEVU_RS485', label: `${this.$t('settings.fromEVU_RS485')}` },
                    { value: 'fromSM_TCP', label: `${this.$t('settings.fromSM_TCP')}` },
                    { value: 'fromEVU_TCP', label: `${this.$t('settings.fromEVU_TCP')}` },
                ]
            },
            sliders: {
                ChargingPower: 100,
                ChargingPrice: 0,
                chargelimit: 0,
                dischargelimit: 0
            },
            checkboxes: {
                PlugIn_UPS: '',
                SmartMeterTest: false,
                P_limitTCP: false,
                P_Control: '',
            }
        };
    },
    // watch: {
    //     timePicker_startCha(newVal) {
    //         if (localStorage.getItem("DynamicETArray")) {
    //             let DynamicETArray = JSON.parse(localStorage.getItem("DynamicETArray"));
    //             if (newVal > DynamicETArray.timePicker_endCha) {
    //                 this.timePicker_endCha = '';
    //             }
    //             else {
    //                 this.timePicker_endCha = DynamicETArray.timePicker_endCha;
    //             }
    //             this.pickerOptions_endCha.minTime = newVal;
    //         }
    //         else {
    //             this.pickerOptions_endCha.minTime = newVal;
    //         }
    //     },
    //     timePicker_startDis(newVal) {
    //         if (localStorage.getItem("DynamicETArray")) {
    //             let DynamicETArray = JSON.parse(localStorage.getItem("DynamicETArray"));
    //             if (newVal > DynamicETArray.timePicker_endCha) {
    //                 this.timePicker_endDis = '';
    //             }
    //             else {
    //                 this.timePicker_endDis = DynamicETArray.timePicker_endDis;
    //             }
    //             this.pickerOptions_endDis.minTime = newVal;
    //         }
    //         else {
    //             this.pickerOptions_endDis.minTime = newVal;
    //         }
    //     },
    // },
    components: {
        [TimeSelect.name]: TimeSelect,
        [Option.name]: Option,
        [Select.name]: Select,
        BaseSwitch,
        BaseCheckbox,
        BaseRadio,
        Slider
    },
    created() {
        this.get_settings();
        setInterval(() => {
            try {
                this.$store.dispatch("get_current_data");
            } catch (error) {
                try {
                    if (this.$store.getters.getAccessStatus === 401) {
                        console.log(error)
                        console.log('Access token expired. Getting new access token...');
                        this.$store.dispatch("getNewAccessToken");
                    }
                }
                catch (error) {
                    this.$router.push('/login');
                }
            }

        }, 30000);

    },
    methods: {
        validateSelection() {

            // if (this.EmergencyPower) {
            //     if (!this.reservesoc) {
            //         this.EPerrorMessage = `${this.$t('settings.reservesocError')}`;
            //     }
            //     else {
            //         this.EPerrorMessage = '';
            //     }
            // } else {
            //     this.EPerrorMessage = '';
            // }

            // if (this.dynamicElectricityTariff) {
            //     if (!this.timePicker_startCha || !this.timePicker_endCha || !this.timePicker_startDis || !this.timePicker_endDis) {
            //         this.errorMessage = `${this.$t('settings.timePickerError')}`;
            //     }
            //     else if (!this.selectedProvider) {
            //         this.errorMessage = `${this.$t('settings.selectedProviderError')}`;
            //     }

            //     else if (this.isTokenReq && !this.TheToken) {
            //         this.errorMessage = `${this.$t('settings.TokenError')}`;
            //     }
            //     else if (this.TheToken && this.TheToken.length < 16) {
            //         this.errorMessage = `${this.$t('settings.TokenLengthError')}`;
            //     }
            //     else {
            //         this.errorMessage = '';
            //     }
            // // }

            // console.log('SmartMeterAddress:', this.SmartMeterAddress);
            // console.log('SmartMeterType:', this.SmartMeterType, 'the length:', this.SmartMeterType.length);
            // console.log('chargelimit:', this.sliders.chargelimit);
            // console.log('dischargelimit:', this.sliders.dischargelimit);

            if (this.SmartMeterType !== null && this.SmartMeterType.length > 3) {
                // SmartMeterType_saved = SmartMeterType_saved.toString();
                this.SmartMeterType = this.selects.SmartMeterType.find(option => option.label === this.SmartMeterType).value;
            }

            if (this.Parameters) {
                if (this.SmartMeterAddress !== '' && this.SmartMeterAddress !== null) {
                    if (this.SmartMeterAddress < 1 || this.SmartMeterAddress > 250) {
                        this.errorMessage = `${this.$t('settings.SmartMeterAddressError')}`;
                    }
                } else {
                    this.errorMessage = '';
                }

                //     if (!this.SmartMeterType) {
                //         this.errorMessage = `${this.$t('settings.SmartMeterTypeError')}`;
                //     }
                //     else if (!this.SmartMeterAddress) {
                //         this.errorMessage = `${this.$t('settings.SmartMeterAddressError')}`;
                //     }
                //     else {
                //         this.errorMessage = '';
                //     }
            }
            else {
                this.errorMessage = '';
            }

        },
        add() {
            this.count++;
        },
        remove() {
            if (this.count >= 1) {
                this.count--;
            }
            else {
                this.count = 0;
            }
        },
        async submit() {
            const numericArray = [];
            for (const key of Object.keys(this.values)) {
                const value = this.values[key];
                // console.log('Value:', value);
                if ((value)) {
                    if (value != 0) {
                        numericArray.push(value);
                    }
                }
            }
            // const allUpperCaseLetters = numericArray.every(item => /^[A-Z]+$/.test(item));
            // if (!allUpperCaseLetters){
            //     Swal.fire({
            //         title: ' ',
            //         text: this.$t('settings.uppercase'),
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: "#285891",
            //     });
            //     return;
            // }
            // console.log('Numeric array:', numericArray);
            this.resetInputs();

            if (numericArray.length > 0) {
                try {



                    const token = localStorage.getItem("access_token");
                    const requestBody = {
                        sn: numericArray // Convert input values to numbers
                    };
                    const endpoint = '/api/auth/add_sn/';

                    const url = process.env.VUE_APP_API_ENDPOINT + endpoint;
                    const headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    //  const message =JSON.stringify(response.data);
                    // console.log('Data:', response.data);
                    const failed = this.$t('settings.failedby');
                    const success = this.$t('settings.successby');
                    const message = `${success} ${response.data["success by"].length}: [${response.data["success by"]}]<br>${failed} ${response.data["failed by"].length}: [${response.data["failed by"]}]<br> ${this.$t('settings.relog')}`;
                    Swal.fire({
                        title: '',
                        html: message,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#285891",
                    });

                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {


                        Swal.fire({
                            title: ' ',
                            text: this.$t('settings.errorMessage'),
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: "#285891",
                        });
                    } else {
                        console.log('Unknown error occurred:', error);
                    }
                }
            }
            else {
                Swal.fire({
                    title: ' ',
                    text: this.$t('settings.message'),
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: "#285891",
                });
            }

        },
        async submit_settings() {

            this.validateSelection();
            if (!this.errorMessage && !this.EPerrorMessage) {
                let senderIp = '';
                // let DynamicETArray = [];
                // let EmergencyArray = [];
                let ParametersArray = [];
                let settingsKey = `settings_${this.selectedSn}`;

                try {
                    const ipResponse = await axios.get('https://api.ipify.org');
                    senderIp = ipResponse.data;
                } catch (error) {
                    console.log('Error fetching IP address:', error);
                }

                // if (this.dynamicElectricityTariff) {
                //     localStorage.setItem("dynamicElectricityTariff", this.dynamicElectricityTariff);
                //     DynamicETArray = {
                //         "timePicker_startCha": this.timePicker_startCha,
                //         "timePicker_endCha": this.timePicker_endCha,
                //         "timePicker_startDis": this.timePicker_startDis,
                //         "timePicker_endDis": this.timePicker_endDis,
                //         "ChargingPrice": Number(String(this.sliders.ChargingPrice).replace(' ct/kWh', '')),
                //         "isProviderSelected": this.isProviderSelected,
                //         "selectedProvider": this.selectedProvider,
                //         "TheToken": this.TheToken
                //     };
                //     // localStorage.setItem("DynamicETArray", JSON.stringify(DynamicETArray));
                // } else {
                //     localStorage.setItem("dynamicElectricityTariff", this.dynamicElectricityTariff);
                //     DynamicETArray = [];
                // }


                // if (this.EmergencyPower) {
                //     localStorage.setItem("EmergencyPower", this.EmergencyPower);
                //     EmergencyArray = {
                //         "ChargingPower": Number(String(this.sliders.ChargingPower).replace(' W', '')),
                //         "reservesoc": this.reservesoc
                //     };
                //     // localStorage.setItem("EmergencyArray", JSON.stringify(EmergencyArray));
                // }
                // else {
                //     localStorage.setItem("EmergencyPower", this.EmergencyPower);
                //     EmergencyArray = [];
                // }
                if (this.SmartMeterAddress === null) {
                    this.SmartMeterAddress = '';
                }
                if (this.SmartMeterType === null) {
                    this.SmartMeterType = '';
                }
                if (this.chargelimit === null) {
                    this.chargelimit = 0;
                }
                if (this.dischargelimit === null) {
                    this.dischargelimit = 0;
                }
                if (this.P_Control === null) {
                    this.P_Control = '';
                }
                if (this.Parameters) {
                    // localStorage.setItem("Parameters", this.Parameters);
                    ParametersArray = {
                        "chargelimit": Number(String(this.sliders.chargelimit).replace(' %', '')),
                        "dischargelimit": Number(String(this.sliders.dischargelimit).replace(' %', '')),
                        "PlugIn_UPS": this.checkboxes.PlugIn_UPS,
                        "SmartMeterTest": this.checkboxes.SmartMeterTest,
                        "P_limitTCP": this.checkboxes.P_limitTCP,
                        "SmartMeterType": this.SmartMeterType,
                        "SmartMeterAddress": this.SmartMeterAddress,
                        "P_Control": this.P_Control
                    };
                    // localStorage.setItem("ParametersArray", JSON.stringify(ParametersArray));
                }
                else {
                    // localStorage.setItem("Parameters", this.Parameters);
                    ParametersArray = {
                        "chargelimit": "0",
                        "dischargelimit": "0",
                        "PlugIn_UPS": "",
                        "SmartMeterTest": false,
                        "P_limitTCP": false,
                        "SmartMeterType": "",
                        "SmartMeterAddress": "",
                        "P_Control": ""
                    };
                }


                let settingsObj = {
                    [this.selectedSn]: {
                        // DynamicETArray,
                        // EmergencyArray,
                        ParametersArray,
                        Parameters: this.Parameters,
                        // senderIp
                    }
                };
                let settingsObjStr = JSON.stringify(settingsObj);
                localStorage.setItem(settingsKey, settingsObjStr);
                // console.log('Dynamic ET Array:', Object.keys(DynamicETArray).length);
                // console.log('Emergency Array:', Object.keys(EmergencyArray).length);
                try {
                    const token = localStorage.getItem("access_token");
                    const requestBody = {
                        // DynamicET: DynamicETArray,
                        // EmergencyPower: EmergencyArray,
                        Parameters: ParametersArray,
                        sn: this.selectedSn,
                        senderIp: senderIp
                    };
                    console.log('Request body:', requestBody);
                    const endpoint = '/api/auth/send_settings/';

                    const url = process.env.VUE_APP_API_ENDPOINT + endpoint;
                    const headers = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    };

                    const response = await axios.post(url, requestBody, { headers });
                    //  const message =JSON.stringify(response.data);
                    // console.log('Data:', response.data);
                    const message = `${response.data.message}`;
                    Swal.fire({
                        title: '',
                        text: this.$t('settings.sendSuccess'),
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#285891",
                    });

                } catch (error) {
                    Swal.fire({
                        title: ' ',
                        text: this.$t('settings.sendError'),
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#285891",
                    });

                }

            }
        },
        resetInputs() {
            for (const key of Object.keys(this.values)) {
                this.values[key] = '';
            }
        },
        getSelectSettings() {
            // this.dynamicElectricityTariff = true;
            let sn = this.selectedSn;
            if (localStorage.getItem(`settings_${sn}`)) {
                let settings = JSON.parse(localStorage.getItem(`settings_${this.selectedSn}`));
                // if (Object.keys(settings[sn].DynamicETArray).length > 0) {
                //     this.dynamicElectricityTariff = true;
                //     this.timePicker_startCha = settings[sn].DynamicETArray.timePicker_startCha;
                //     this.timePicker_endCha = settings[sn].DynamicETArray.timePicker_endCha;
                //     this.timePicker_startDis = settings[sn].DynamicETArray.timePicker_startDis;
                //     this.timePicker_endDis = settings[sn].DynamicETArray.timePicker_endDis;
                //     this.sliders.ChargingPrice = settings[sn].DynamicETArray.ChargingPrice;
                //     this.selectedProvider = settings[sn].DynamicETArray.selectedProvider;
                //     this.TheToken = settings[sn].DynamicETArray.TheToken;
                // } else {
                //     this.dynamicElectricityTariff = false;
                // }


                // if (Object.keys(settings[sn].EmergencyArray).length > 0) {
                //     this.EmergencyPower = true;
                //     this.sliders.ChargingPower = settings[sn].EmergencyArray.ChargingPower;
                //     this.reservesoc = settings[sn].EmergencyArray.reservesoc;
                // }
                // else {
                //     this.EmergencyPower = false;
                // }


                if (Object.keys(settings[sn].ParametersArray).length > 0) {

                    this.Parameters = settings[sn].Parameters;
                    this.sliders.chargelimit = settings[sn].ParametersArray.chargelimit;
                    this.sliders.dischargelimit = settings[sn].ParametersArray.dischargelimit;
                    this.checkboxes.PlugIn_UPS = settings[sn].ParametersArray.PlugIn_UPS;
                    // this.checkboxes.UPS = settings[sn].ParametersArray.UPS;
                    this.checkboxes.SmartMeterTest = settings[sn].ParametersArray.SmartMeterTest;
                    this.checkboxes.P_limitTCP = settings[sn].ParametersArray.P_limitTCP;
                    let SmartMeterType_saved = settings[sn].ParametersArray.SmartMeterType;
                    if (SmartMeterType_saved !== null) {
                        SmartMeterType_saved = SmartMeterType_saved.toString();
                        this.SmartMeterType = this.selects.SmartMeterType.find(option => option.value === SmartMeterType_saved).label;
                        console.log('SmartMeterType_saved:', SmartMeterType_saved, typeof (SmartMeterType_saved));
                        console.log('SmartMeterType:', this.selects.SmartMeterType.find(option => option.value === SmartMeterType_saved).label);
                    }
                    this.SmartMeterAddress = settings[sn].ParametersArray.SmartMeterAddress;
                    this.P_Control = settings[sn].ParametersArray.P_Control;
                }
                else {
                    this.Parameters = false;
                }
            }
            else {
                // this.dynamicElectricityTariff = false;
                // this.EmergencyPower = false;
                this.Parameters = false;
            }
        },
        async get_settings() {
            const endpoint = '/api/auth/get_settings/';
            let response = await useApi.get(endpoint);
            response.data.forEach(obj => {
                try {
                    const selectedSn = Object.keys(obj)[0];
                    if (selectedSn !== "error") {
                        const settingsKey = `settings_${selectedSn}`;
                        const { ParametersArray, Parameters, senderIp } = obj[selectedSn];
                        const settingsObj = {
                            [selectedSn]: {
                                ParametersArray,
                                Parameters,
                                senderIp
                            }
                        };
                        const settingsObjStr = JSON.stringify(settingsObj);
                        // console.log(Object.values(obj)[0].ParametersArray);
                        localStorage.setItem(settingsKey, settingsObjStr);
                        console.log(settingsObj);
                    }
                } catch (error) {
                    console.log('Error:', error);
                }
            });
            return response;
        },
        validateNumber() {
            this.SmartMeterAddress = this.SmartMeterAddress.replace(/[^0-9]/g, '').slice(0, 3);

            let numericValue = Number(this.SmartMeterAddress);
            if (numericValue < 1 || numericValue > 250 || isNaN(numericValue)) {
                this.errorMessage = `${this.$t('settings.SmartMeterAddressError')}`;
            } else {
                this.errorMessage = '';
            }
        },
        uncheckPI() {
            if (this.checkboxes.PlugIn_UPS === 'PlugIn') {
                this.checkboxes.PlugIn_UPS = '';
            } else {
                this.checkboxes.PlugIn_UPS = 'PlugIn';
            }
        },
        uncheckUPS() {
            if (this.checkboxes.PlugIn_UPS === 'UPS') {
                this.checkboxes.PlugIn_UPS = '';
            } else {
                this.checkboxes.PlugIn_UPS = 'UPS';
            }
        },
    },
    computed: {
        ...mapState(['darkMode']),
        darkMode() {
            return this.$store.getters.getDarkMode;
        },
        isTokenReq() {
            return this.selectedProvider === "Tibber";
        },
        isProviderSelected() {
            return this.selectedProvider !== null;
        },
        getAllSn() {

            return this.$store.getters.getSnArray;
        },
    },

}
</script>

<style scoped>
/* For iPad (Tablet) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .card-style {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 20px;
    }
}

/* For Normal Phones */
@media screen and (max-width: 767px) {
    .controler {
        width: 100% !important;
        margin: 15px auto;
    }

    .btnn {
        width: 50% !important;
    }

    h1 {
        text-align: center;
        font-size: 1.2em !important;


    }

    .card-style {

        width: 100% !important;
        height: 100%;
        border-radius: 5px;
        padding: 20px;
    }

    .col-md-6 {
        width: 50% !important;
    }
}

.card-container {
    width: 100%;
}


.card-style {
    width: 50%;
    height: 100%;
    border-radius: 5px;
    padding: 20px;
}

#add_more_fields i {
    padding-right: 10px;
    /* Adjust the padding as needed */
}

#remove_fields i {
    padding-right: 10px;
    /* Adjust the padding as needed */
}

.btnn {
    width: 20%;

    color: white;
    border: 0;
    padding: 7px;
    border-radius: 5px;


}

.wrapper {
    width: 50%;
    height: 50%;
    margin: 40px auto;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 10px 40px 0px rgba(47, 47, 47, 0.1);
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px auto;
    display: block;
    border-radius: 5px;
    border: 1px solid #555;
    width: 100%;
    background: none;

}

input[type="text"]:focus {
    outline: none;
}

.controler {
    width: 60%;
    margin: 15px auto;
}

#remove_fields {
    float: right;

}

.controle a i.fa-minus {
    margin-right: 10px;
}

h1 {
    text-align: center;
    font-size: 2em;


}

.text-white {
    color: #000 !important;
}

.text-dark {
    color: #fff !important;
}

.settings {
    margin-top: 20px;
}

.setting-item {
    margin-bottom: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, max-height 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    max-height: 1.5s;
}

.custom-time-select.is-disabled {
    opacity: 0.3;
}

.error-message {
    color: #E46962;
    margin-top: 5px;
    text-align: center;
}

.settings.setting-item input {
    border: 1px solid #2b3553;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}

.settings.setting-item input::placeholder {
    color: #BBBFC3;
}
</style>