import i18n from "@/i18n";

export const getTimeSeries = (seriesSelected) => {
  if (seriesSelected == "m1" || seriesSelected == "m2" || seriesSelected == "m4" || seriesSelected == "m5" || seriesSelected == "m6" || seriesSelected == "m0")
    return [
      // { value: "quarter", text: i18n.t("Quarter") },
      { value: "quarter", text: i18n.t("bigchart.Day") },
      // { value: "day", text: i18n.t("Day") },
      { value: "month", text: i18n.t("bigchart.Month") },
      { value: "year", text: i18n.t("bigchart.Year") },
    ];
  else if (seriesSelected == "m3" || seriesSelected == "m7")
    return [
      // { value: "quarter", text: i18n.t("Quarter") },
      //{ value: "quarter", text: i18n.t("Day") },
      { value: "day", text: i18n.t("bigchart.Day") },
      { value: "month", text: i18n.t("bigchart.Month") },
      { value: "year", text: i18n.t("bigchart.Year") },
    ];
  else if (seriesSelected == "energy")
    return [
      { value: "week", text: i18n.t("smallchart.Week") },
      { value: "month", text: i18n.t("smallchart.Month") },
      { value: "year", text: i18n.t("smallchart.Year") },
      {value: "total", text: i18n.t("smallchart.Total")}
    ];
  else {
    return [
      // { value: "quarter", text: i18n.t("Quarter") },
      { value: "quarter", text: i18n.t("bigchart.Day") },
      //{ value: "day", text: i18n.t("Day") },
    ];
  }
};

export const getSeriesLabel = (sn) => {
  let seriesLabel = [
    { value: "m0", text: i18n.t("bigchart.Powers") },
    { value: "m5", text: i18n.t("bigchart.Battery power") },
    { value: "m6", text: i18n.t("bigchart.SOC") },
    { value: "m7", text: i18n.t("bigchart.Energy") },
    //{ value: "m3", text: i18n.t("Discharge Energy") },
    { value: "m4", text: i18n.t("bigchart.PV_power") },
    { value: "m2", text: i18n.t("bigchart.Gird Power") },
    { value: "m1", text: i18n.t("bigchart.Grid voltage") },
    // { value: "energy_forecast", text: i18n.t("bigchart.Energy forecast") },
    // { value: "load_forecast", text: i18n.t("bigchart.Load forecast") },
  ];
  if (sn == -1) {
    seriesLabel = [
      { value: "m0", text: i18n.t("bigchart.Powers") },
      { value: "m5", text: i18n.t("bigchart.Battery power") },
      { value: "m6", text: i18n.t("bigchart.SOC") },
      { value: "m7", text: i18n.t("bigchart.Energy") },
      //{ value: "m3", text: i18n.t("Discharge Energy") },
      // { value: "energy_forecast", text: i18n.t("bigchart.Energy forecast") },
      // { value: "load_forecast", text: i18n.t("bigchart.Load forecast") },
    ];
  }
  return seriesLabel;
};
export const getSeriesUnit = () => {
  return [
    { value: "m0", text: " [W]" },
    { value: "m5", text: " [W]" },
    { value: "m6", text: " [%]" },

    { value: "m7", text: " [Wh]" },
    { value: "m3", text: " [Wh]" },
    { value: "m4", text: " [W]" },
    { value: "m2", text: " [W]" },
    { value: "m1", text: " [V]" },
    // { value: "energy_forecast", text: "[W]" },
    // { value: "load_forecast", text: "[W]" },
  ];
};

export const getSeries = (sn) => {
  let series = [
    { value: "m0", text: i18n.t("bigchart.Total output") },
    { value: "m5", text: i18n.t("bigchart.Battery power") },
    { value: "m6", text: i18n.t("bigchart.SOC") },
    { value: "m7", text: i18n.t("bigchart.Charge Energy") },
    { value: "m3", text: i18n.t("bigchart.Discharge Energy") },
    { value: "m4", text: i18n.t("bigchart.PV_power") },
    { value: "m2", text: i18n.t("bigchart.Grid Power") },
    { value: "m1", text: i18n.t("bigchart.Grid voltage") },
    // { value: "energy_forecast", text: i18n.t("bigchart.Energy forecast") },
    // { value: "load_forecast", text: i18n.t("bigchart.Load forecast") },
    //{ value: "m2_out", text: i18n.t("Grid Power") },

    //{ value: "m5_out", text: i18n.t("Battery power") },
  ];

  if (sn == -1) {
    series = [
      { value: "m0", text: i18n.t("bigchart.Total output"), selected: true },
      { value: "m5", text: i18n.t("bigchart.Battery power") },
      { value: "m6", text: i18n.t("bigchart.SOC") },
      { value: "m7", text: i18n.t("bigchart.Charge Energy") },
      { value: "m3", text: i18n.t("bigchart.Discharge Energy") },
      // { value: "energy_forecast", text: i18n.t("bigchart.Energy forecast") },
      // { value: "load_forecast", text: i18n.t("bigchart.Load forecast") },
      // { value: "m4", text: i18n.t("PV_power") },
      // { value: "m2", text: i18n.t("Grid Power") },
      // { value: "m1", text: i18n.t("Grid voltage") },

      //{ value: "m2_out", text: i18n.t("Grid Power") },

      //{ value: "m5_out", text: i18n.t("Battery power") },
    ];
  }

  return series;
};